import { Vector2 } from "three";

const uniforms = {
  tDiffuse: { value: null },
  tSize: { value: new Vector2(256, 256) },
  center: { value: new Vector2(0.5, 0.5) },
  angle: { value: 1.57 },
  scale: { value: 1.0 },
};

const vertexShader = /* glsl */ `
  varying vec2 vUv;

  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

const createPattern = (angle, scale, tex, center) => {
  const s = Math.sin(angle);
  const c = Math.cos(angle);
  const point = new Vector2(
    c * tex.x - s * tex.y,
    s * tex.x + c * tex.y
  ).multiplyScalar(scale);
  return Math.sin(point.x) * Math.sin(point.y) * 4.0;
};

const random = (p) => {
  const k1 = new Vector2(23.14069263277926, 2.665144142690225);
  return Math.fract(Math.cos(Vector2.dot(p, k1)) * 12345.6789);
};

const fragmentShader = /* glsl */ `
  uniform vec2 center;
  uniform float angle;
  uniform float scale;
  uniform vec2 tSize;
  uniform sampler2D tDiffuse;
  varying vec2 vUv;

  float pattern() {
    float s = sin(angle);
    float c = cos(angle);
    vec2 tex = vUv * tSize - center;
    vec2 point = vec2(c * tex.x - s * tex.y, s * tex.x + c * tex.y) * scale;
    return sin(point.x) * sin(point.y) * 4.0;
  }

  float random(vec2 p) {
    vec2 k1 = vec2(
        23.14069263277926,
        2.665144142690225
    );
    return fract(
        cos(dot(p, k1)) * 12345.6789
    );
  }

  void main() {
    vec4 color = texture2D(tDiffuse, vUv);
    vec2 uvrandom = vUv;
    uvrandom.y *= random(vec2(uvrandom.y, 0.4));
    color.rgb += random(uvrandom) * 0.1;
    gl_FragColor = color;
  }
`;

const DotScreenShader = {
  uniforms,
  vertexShader,
  fragmentShader,
};

export { DotScreenShader };
