uniform float time;
uniform float progress;
uniform sampler2D texture1;
uniform vec4 resolution;
varying vec2 vUv;
varying vec3 vPosition;
const float PI = 3.141592653589793238;

// NOISE
float mod289(float x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
vec4 mod289(vec4 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
vec4 perm(vec4 x) { return mod289(((x * 34.0) + 1.0) * x); }

float noise(vec3 p) {
    vec3 a = floor(p);
    vec3 d = p - a;
    vec3 d2 = d * d * (3.0 - 2.0 * d);

    vec4 b = a.xxyy + vec4(0.0, 1.0, 0.0, 1.0);
    vec4 k1 = perm(b.xyxy);
    vec4 k2 = perm(k1.xyxy + b.zzww);

    vec4 c = k2 + a.zzzz;
    vec4 k3 = perm(c);
    vec4 k4 = perm(c + 1.0);

    vec4 o1 = fract(k3 * (1.0 / 41.0));
    vec4 o2 = fract(k4 * (1.0 / 41.0));

    vec4 o3 = mix(o1, o2, d.z);
    vec2 o4 = mix(o3.xz, o3.yw, d.x);

    return mix(o4.x, o4.y, d.y);
}

float lines(vec2 uv, float offset) {
    return smoothstep(
        0., 0.5 + offset*0.5,
        0.5*abs((sin(uv.x*35.) + offset*2.))
    );
}

mat2 rotate2D(float angle) {
    float c = cos(angle);
    float s = sin(angle);
    return mat2(c, -s, s, c);
}

vec3 mixColors(vec3 color1, vec3 color2, float t) {
    return mix(color1, color2, t);
}

vec3 applyPatterns(vec2 uv, vec3 baseFirst, vec3 baseSecond, vec3 accent) {
    float basePattern = lines(uv, 0.5);
    float secondPattern = lines(uv, 0.1);

    vec3 baseColor = mixColors(baseSecond, baseFirst, basePattern);
    return mixColors(baseColor, accent, secondPattern);
}

void main() {
    const vec3 baseFirst = vec3(10./255., 36./255., 99./255.); // Dark blue
    const vec3 accent = vec3(230./255., 210./255., 190./255.); // Soft Beige
    const vec3 baseSecond = vec3(100./255., 116./255., 68./255.); // Olive Green

    float n = noise(vPosition + time);
    vec2 baseUV = rotate2D(n) * vPosition.xy * 0.1;

    vec3 finalColor = applyPatterns(baseUV, baseFirst, baseSecond, accent);

    gl_FragColor = vec4(finalColor, 3.);
}