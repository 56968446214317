import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { DotScreenShader } from "./CustomShader";
import fragment from "./shader/fragment.glsl";
import fragment1 from "./shader/fragment1.glsl";
import vertex from "./shader/vertex.glsl";
import vertex1 from "./shader/vertex1.glsl";

class Sketch {
  constructor(options) {
    this.initScene(options);
    this.initRenderer();
    this.initCamera();
    this.initPostProcessing();
    this.addObjects();
    this.setupEventListeners();
    this.animate();
  }

  initScene(options) {
    this.scene = new THREE.Scene();
    this.scene.alpha = true;
    this.container = options.dom;
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.time = 0;
    this.isPlaying = true;
  }

  initRenderer() {
    this.renderer = new THREE.WebGLRenderer();
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor(0xeeeeee, 1);
    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.container.appendChild(this.renderer.domElement);
  }

  initCamera() {
    this.camera = new THREE.PerspectiveCamera(
      70,
      this.width / this.height,
      0.001,
      1000
    );
    this.camera.position.set(-0.3, -0.3, 1.3);
    this.camera.lookAt(0, 0, 0);
  }

  initPostProcessing() {
    this.composer = new EffectComposer(this.renderer);
    this.composer.addPass(new RenderPass(this.scene, this.camera));

    const effect1 = new ShaderPass(DotScreenShader);
    effect1.uniforms.scale.value = 4;
    this.composer.addPass(effect1);
  }

  addObjects() {
    this.createMainSphere();
    this.createSmallSphere();
  }

  createMainSphere() {
    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable",
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        resolution: { value: new THREE.Vector4() },
      },
      vertexShader: vertex,
      fragmentShader: fragment,
    });

    const geometry = new THREE.SphereBufferGeometry(1.5, 32, 32);
    this.plane = new THREE.Mesh(geometry, this.material);
    this.scene.add(this.plane);
  }

  createSmallSphere() {
    this.cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256, {
      format: THREE.RGBAFormat,
      generateMipmaps: true,
      minFilter: THREE.LinearMipMapLinearFilter,
      encoding: THREE.sRGBEncoding,
    });

    this.cubeCamera = new THREE.CubeCamera(0.1, 10, this.cubeRenderTarget);

    const geometry = new THREE.SphereBufferGeometry(0.4, 32, 32);
    this.smallSphereMaterial = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable",
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0 },
        tCube: { value: 0 },
        mRefractionRatio: { value: 1.02 },
        mFresnelBias: { value: 0.1 },
        mFresnelScale: { value: 4 },
        mFresnelPower: { value: 2 },
        resolution: { value: new THREE.Vector4() },
      },
      vertexShader: vertex1,
      fragmentShader: fragment1,
    });

    this.smallSphere = new THREE.Mesh(geometry, this.smallSphereMaterial);
    this.smallSphere.position.z += 0.8;
    this.scene.add(this.smallSphere);
  }

  setupEventListeners() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  resize() {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer.setSize(this.width, this.height);
    this.composer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
  }

  stop() {
    this.isPlaying = false;
  }

  play() {
    if (!this.isPlaying) {
      this.isPlaying = true;
      this.animate();
    }
  }

  animate = () => {
    if (!this.isPlaying) return;
    this.time += 0.01;
    this.updateSmallSphere();
    this.updateMaterials();
    requestAnimationFrame(this.animate);
    this.composer.render(this.scene, this.camera);
  };

  updateSmallSphere() {
    this.smallSphere.visible = false;
    this.cubeCamera.update(this.renderer, this.scene);
    this.smallSphere.visible = true;
  }

  updateMaterials() {
    this.smallSphereMaterial.uniforms.tCube.value =
      this.cubeRenderTarget.texture;
    this.material.uniforms.time.value = this.time;
  }
}

const dom = document.getElementById("container");

new Sketch({ dom });

document.addEventListener("DOMContentLoaded", () => {
  const logoSymbol = document.getElementById("logoSymbol");
  const logoText = document.getElementById("logoText");

  logoSymbol.addEventListener("mouseenter", () => {
    logoText.classList.add("visible");
  });

  logoSymbol.addEventListener("mouseleave", () => {
    logoText.classList.remove("visible");
  });
});
